/* eslint-disable no-unused-expressions */
import {createSlice} from '@reduxjs/toolkit';

export const CONFIG_INIT = 0;
export const CONFIG_LOADED = 1;
export const CONFIG_ERROR = 2;


export const configSlice = createSlice({
  name: 'config',
  initialState:  {
    navlinks: [],
    footer: [],
    extra: []
  },
  reducers: {
    fetchConfig: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

      state
    },
    getConfig: (state) => {
      state
    },
    // eslint-disable-next-line no-unused-vars
    error: (state, action) => {
      state
    },
  },
})

// Action creators are generated for each case reducer function
export const { fetchConfig, getConfig, error } = configSlice.actions;
export default configSlice.reducer;

// const config = (state, action) => {
//   const defaultState = {
//     navlinks: [],
//     footer: [],
//     extra: []
//   };
//   switch (action.type) {
//     case CONFIG_INIT:
//       return {
//         ...defaultState
//       };
//     case CONFIG_LOADED:
//       return {
//         ...defaultState,
//         ...state
//       }
//     case CONFIG_ERROR:
//       return {
//         ...defaultState,
//         ...state
//       }
//     default:
//       return {
//         ...defaultState
//       };
//   }
// };


// export default config;