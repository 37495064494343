import { Component } from 'react';
import { createInstance } from 'react-async';
import Skeleton from 'react-loading-skeleton';
import { Figure } from 'react-bootstrap';
import './Instagram.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/swiper.min.css';
import { FreeMode, Lazy } from 'swiper';
// import 'swiper/css';

export default class Instagram extends Component {

  constructor(props) {
    super(props);
    this.loadInstagramData = this.loadInstagramData.bind(this);
    this.showItem = this.showItem.bind(this);
  }

// eslint-disable-next-line
  // class-methods-use-this,react/no-unused-class-component-methods
  // eslint-disable-next-line class-methods-use-this
  loadInstagramData = async ({ signal }) => {
    const response = await fetch('/api/instagram/list', { signal });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  };

  // eslint-disable-next-line class-methods-use-this
  showItem = (data) => {
    const { imageUrl, title } = data;
    console.log('test', data);
    return <Figure>
      <Figure.Image
        // width={ 150 }
        // height={ 150 }
        alt={ title }
        src={ imageUrl }
      />
      {/* <Figure.Caption> */}
      {/*  { title } */}
      {/* </Figure.Caption> */}
    </Figure>;
  };

  render() {
    const AsyncInstagram = createInstance({ promiseFn: this.loadInstagramData },
      'Instagram');
    return (
      <div className="pt-4">
        <div className="row text-center"><h3>Check ook mijn Instagram</h3></div>
        <div className="row">
            <AsyncInstagram>
              <AsyncInstagram.Pending>
                <Skeleton count={ 10 } />
              </AsyncInstagram.Pending>
              <AsyncInstagram.Rejected>
                <div className="alert-warning">Something went wrong</div>
              </AsyncInstagram.Rejected>
              <AsyncInstagram.Fulfilled>{ (data) => (
                <Swiper
                  slidesPerView="auto"
                  spaceBetween={0}
                  width={100}
                  lazy
                  freeMode
                  modules={[Lazy, FreeMode]}
                  className="instagramSwiper"
                >
                  { data.map(item =>
                  <SwiperSlide>
                    <a href={ item.url } target="_blank" rel="noreferrer">
                      { this.showItem(item) }
                    </a></SwiperSlide>
                ) }
                </Swiper>)
              }</AsyncInstagram.Fulfilled>
            </AsyncInstagram>
        </div>
      </div>
    );
  }
}