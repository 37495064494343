import { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

export default class FooterItem extends Component {

  render() {
    const {id, title, target, url, type} = this.props;
    console.debug('type in footer', type);
    return (
        <li key={id} className="nav-item">
          <NavLink className="nav-link p-0 mb-2" to={url} target={`_${target}`}>{ title }</NavLink>
        </li>
    );
  }
}

FooterItem.propTypes = {
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      target: PropTypes.string.isRequired,
      url: PropTypes.string,
};

FooterItem.defaultProps = {
  url: '',
};