import { Component } from 'react';
import PropTypes from 'prop-types';
import FooterItem from './FooterItem';

export default class FooterBlock extends Component {

  render() {
    const {
      key, title, items
    } = this.props;
    console.table(items);
    return (
      <div className="col" key={ key }>
        <h5 className="card-title">{ title }</h5>
        <ul className="navbar-nav flex-column">
          { items.map(item => <FooterItem id={ item.id }
                                          title={ item.title }
                                          type={ item.type }
                                          target={ item.target }
                                          url={ item.url ?? '' } />
            ) }
        </ul>
      </div>
    );
  }
}

FooterBlock.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types,react/forbid-prop-types
  // group: PropTypes.shape({
  key: PropTypes.number,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      type: PropTypes.string,
      target: PropTypes.string,
      url: PropTypes.string,
      sortOrder: PropTypes.number
    })
  )
  // }).isRequired
};

FooterBlock.defaultProps = {
  key: Math.random(),
  items: []
};
