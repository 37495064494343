import { Component } from 'react';
import Instagram from './Instagram';
import './footer.scss';
import { createInstance } from 'react-async';
import FooterBlock from './FooterBlock';

export default class Footer extends Component {

  // eslint-disable-next-line class-methods-use-this
  loadFooterData = async ({ signal }) => {
    const response = await fetch('/api/footer', { signal });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  };

  render() {

    const AsyncFooter = createInstance({ promiseFn: this.loadFooterData },
      'Footer');

    return (
      <footer className="bg-tertiary">
        <div className="container-fluid bg-secondary">
          <Instagram />
        </div>
        <div className="container">
          <div className="row pt-2">
            <AsyncFooter>
              <AsyncFooter.Pending>Loading...</AsyncFooter.Pending>
              <AsyncFooter.Rejected>Error...</AsyncFooter.Rejected>
              <AsyncFooter.Fulfilled>{ data => (data.
                  sort((le, re) => le.sortOrder > re.sortOrder).
                  map((group) => <FooterBlock key={ group.id }
                                              title={ group.title }
                                              items={ group.items } />)
              ) }</AsyncFooter.Fulfilled>
            </AsyncFooter>
          </div>
          <div className="row">
            <hr />
          </div>
          <div className="row copyright">
            <p>(c) 2022 Mwar Consulting & Software Development. Alle rechten
              voorbehouden</p>
          </div>
        </div>
      </footer>
    );
  }
}