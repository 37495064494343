import { Component } from 'react';
import { Container, NavbarBrand } from 'react-bootstrap';
import Navbar from './Navbar';
import './header.scss';

export default class Header extends Component {

  componentDidMount() {
    console.log('header dit mount');
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log('compoentnet dit update');
  }

  render() {
    return (
        <header className="navbar navbar-expand-lg navbar-dark bg-secondary navbar-fixed-top">
          <Container fluid>
            <div className="navbar-header">
              <NavbarBrand href='/'><img height={75} src="https://via.placeholder.com/250x94?text=Ellen Fotografie" alt="Logo Ellen fotografie" /></NavbarBrand>
            </div>
              <Navbar/>
          </Container>
        </header>
    );
  }
}