import './App.scss';
import {Component} from 'react';
import {Route, Routes, useParams} from 'react-router-dom';
import Header from './component/ui/header/header';
import Footer from './component/ui/footer/footer';
import DefaultPage from './pages/DefaultPage';
import ErrorPage from './pages/ErrorPage';

// function Home() {
//   console.log(' home');
//   return <h2>Home</h2>;
// }
export default class App extends Component {

  componentDidMount() {
    console.log('APP mount');
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log('APP update');
  }

  // eslint-disable-next-line class-methods-use-this
  renderRoute = () => (
      <Routes>
        <Route path="/"
               element={ <Rendering isHome/> }/>
        <Route path=":urlKey"
               element={ <Rendering/> }>
          <Route path=":urlKey1"
                 element={ <Rendering/> }>
            <Route path=":urlKey2"
                   element={ <Rendering/> }>
              <Route path=":urlKey3"
                     element={ <Rendering/> }>
                <Route path=":urlKey4"
                       element={ <Rendering/> }>
                  <Route path=":urlKey5"
                         element={ <Rendering/> }/>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="*" element={ <ErrorPage/> }/>
      </Routes>
  );

  render() {
    const {renderRoute} = this;
    return (
        <div className="App">
          <Header/>
          { renderRoute() }
          <Footer/>
        </div>
    );
  }
}

export function Rendering(props) {
  const path = Object.values(useParams()).join('/');
  // eslint-disable-next-line react/prop-types
  const { isHome = false } = props;
  console.log(`go to path  = ${ path } | is home = ${ isHome }`,);
  return <div className={ isHome? 'container-fluid': 'container'}><DefaultPage pad={ path } isHome={ isHome }/></div>;
}

