/* eslint-disable react/no-unused-state */
import { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { createInstance } from 'react-async';
import Skeleton from 'react-loading-skeleton';
import './Navbar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckSquare,
  faCoffee,
  faPeopleRobbery
} from '@fortawesome/free-solid-svg-icons';
// import { faCoffee } from '@fortawesome/free-solid-svg-icons';

export default class Navbar extends Component {

  static isLoading = false;

  constructor(props, context) {
    super(props, context);
    this.state = {
      isLoading: false,
      isLoaded: false,
      items: [],
      error: false
    };
    this.loadMenuData = this.loadMenuData.bind(this);
  }

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (nextState.isLoading === true) {
      return false;
    }
    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  handleChild = (item) => {
    const { id, title, type, target, url } = item;
    let link;
    if (url !== undefined && url !== null) {
      link = <NavLink className="dropdown-item" id={ id }
                      target={ `_${ target.toLowerCase() }` }
                      to={ url }>{ title } { type === 'PAGE'
        ? ' (p)'
        : '' }</NavLink>;
    }
    return <li key={ id }>{ link ??
      <p className="dropdown-item">{ title }</p> }</li>;
  };

  handleChildren = (children) => <ul className="dropdown-menu">
    { children.sort(
        (le, re) => le.sortOrder > re.sortOrder).
      map(i => this.handleChild(i)) }</ul>;

  handeMenuItem = (item) => {
    const { id, title, children, target, url, type } = item;
    let subItem;

    if (type === 'DIVIDER') {
      return  <li
        className="nav-divider"
        key={ id }>
        <div className="divider" />
      </li>
    }

    if (children.length > 0) {
      subItem = this.handleChildren(children);
    }

    let text = title;

    if (type === 'ICON') {
      // eslint-disable-next-line default-case
      switch(title.toLowerCase()) {
        case 'facebook':
          text = <FontAwesomeIcon icon={faCoffee} />
          break;
        case 'instagram':
          text = <FontAwesomeIcon icon={faCheckSquare} />
          break;
        case 'whatsapp':
          text = <FontAwesomeIcon icon={faPeopleRobbery} />
          break;
      }
    }

    let link;
    if (url !== undefined && url !== null) {
      link = <NavLink className="nav-link" id={ id }
                      target={ `_${ target.toLowerCase() }` }
                      to={ url }>{ text }</NavLink>;
    }

    return <li
      className={ `nav-item ${ children.length > 1 ? 'dropdown' : '' }` }
      key={ id }>{ link ?? <span className='nav-link'>{ text }</span> }
      { subItem }
    </li>;
  };



  // eslint-disable-next-line class-methods-use-this
  loadMenuData = async ({ depth }, { signal }) => {
    const response = await fetch(`/api/menu?depth=${ depth }`, { signal });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  };

  render() {
    // createInstance takes a defaultOptions object and a displayName (both
    // optional)
    const AsyncMenu = createInstance({ promiseFn: this.loadMenuData }, 'Menu');
    const navClass = (isActive) => `nav-link ${ isActive ? 'active' : '' }`;
    const loadings = <li className="nav-item"><Skeleton className="nav-link" />
    </li>;

    return (
      <nav className="collapse navbar-collapse main-menu">
        <ul className="navbar-nav ms-auto">
          <AsyncMenu depth={ 0 }>
            <AsyncMenu.Loading>{ loadings }{ loadings }{ loadings }{ loadings }{ loadings }</AsyncMenu.Loading>
            { /* <AsyncMenu.Rejected><li className="nav-item">{error =>
         `Something went wrong: ${error.message}`}</li></AsyncMenu.Rejected>
          */ }
            <AsyncMenu.Rejected>
              <li className="nav-item">
                <NavLink className={ navClass } to="/">Pagina 1</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className={ navClass } to="testasf">Pagina 2</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className={ navClass } to="/pagina3">Pagina 3</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className={ navClass } to="/pagina3/pagina4">Pagina
                  4</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className={ navClass } to="/error">Pagina 5</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className={ navClass } to="pagina6/pagina7/agina8">Pagina
                  6</NavLink>
              </li>
            </AsyncMenu.Rejected>
            <AsyncMenu.Fulfilled>{ data => ( data.
                sort((le, re) => le.sortOrder > re.sortOrder).
                map(this.handeMenuItem)
            ) }
            </AsyncMenu.Fulfilled>
            <li className="nav-item" />
          </AsyncMenu>
        </ul>
      </nav>
    );
  }
}