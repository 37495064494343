import {Component} from 'react';

export default class ErrorPage extends Component {

  render() {
    return (
        <div>Something went wrong</div>
    );
  }
}
