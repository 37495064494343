import { Component } from 'react';
import './DefaultPage.scss';

import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

class DefaultPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isLoaded: false,
      isError: false,
      data: {}
    }
    ;

  }

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    console.log('pagina mound');
    // eslint-disable-next-line react/destructuring-assignment
    this.getFromBackend(this.props.pad);
    // eslint-disable-next-line react/destructuring-assignment
    // this.props.mounting();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log('pagina updated');
    // eslint-disable-next-line react/destructuring-assignment
    if (this.props.pad !== prevProps.pad) {
      this.getFromBackend(this.props.pad);
    }

  }

  async getFromBackend(urlKey) {
    this.setState({
      isLoading: true,
      isLoaded: false,
      isError: false
    });

    await fetch(`/api/${ escape(urlKey) }`).
      then(res => res.json()).
      then((result) => {
          this.setState({
            isLoading: false,
            isLoaded: true,
            isError: false,
            data: result
          });
        }, (error) => {
          console.error(error);
          this.setState({
            isLoading: false,
            isLoaded: false,
            isError: true
          });
        }
      ).catch((error) => {
        console.error(error);
        this.setState({
          isLoading: false,
          isLoaded: true,
          isError: true
        });
      });

  }

  render() {
    const { data, isLoading, isLoaded, isError } = this.state;
    // const {pad, isHome} = this.props;

    // eslint-disable-next-line react/destructuring-assignment,react/prop-types
    // console.log(this.props.match.params);
    // eslint-disable-next-line react/destructuring-assignment

    if (isLoading) {
      return <div className="body"><Skeleton count={ 10 } /></div>;
    }
    if (isError) {
      return <div className="body">Er ging iets mis: <p>test</p></div>;
    }
    console.log(`Loaded? ${ isLoaded }`);
    return (
      <div className="body container">
        <h1 className="mt-2 text-center">{ data.title }</h1>
        <div>{ parse(data.content) }</div>
      </div>
    );
  }
}

DefaultPage.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  pad: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  isHome: PropTypes.bool
  // eslint-disable-next-line react/no-unused-prop-types
};
//
DefaultPage.defaultProps = {
  pad: ' ??',
  isHome: false
};

export default DefaultPage;
